//import { useAuth0 } from "@auth0/auth0-react";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import logo from '../../assets/img/atlasai-logo-black-small.png';
//import { ReactComponent as Avatar } from "../../assets/img/avatar.svg";
import useOrganizationLogo from '../../hooks/calls/useOrganizationLogo';
import { AuthenticationButton } from '../Buttons/authentication-button';
import { TopLevelTabButton } from '../Buttons/StyledAntdButton';
import SaveView from './SaveProject';
import Upload from './Upload';
import SelectEnv from './SelectEnv';
import SelectProjectView from './SelectView';
import Spinner from '../Loading/Spinner';
import paths from '../../constants/paths';
import useGuards from '../../hooks/useGuards';

import useEnvs from '../../hooks/calls/useEnvs';
import { isNilOrEmpty } from '@/utils/validator';

const Navbar = styled.div`
  background: #fff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid #f6f8fa;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavItemsOuter = styled(NavContainer)`
  flex: 1;
`;

const NavItemsInnerLeft = styled(NavContainer)`
  margin-right: auto;
`;

const NavItemsInnerRight = styled(NavContainer)`
  margin-left: auto;
`;

const Header = (props) => {
  const { page } = props;
  const isEnvironmentsPage = page === 'environments';
  const history = useHistory();
  const location = useLocation();
  const orgLogo = useOrganizationLogo();
  const { hasFeature, hasPermission } = useGuards();

  const currentEnv = useSelector((state) => state.app.currentEnv);
  const { data, isLoading: isEnvListLoading } = useEnvs();
  const envList = pathOr([], ['envs'], data);
  const currentEnvConfig = isEnvListLoading
    ? null
    : envList.find((env) => env.id === currentEnv) || envList[0];

  const leftItems = () => {
    return (
      <>
        <div
          onClick={() => {
            history.push(paths.yourEnvironments);
          }}
          className='pointer mr-16'
        >
          {orgLogo.isLoading ? (
            <Spinner />
          ) : (
            <img
              src={orgLogo.data}
              id='base64image'
              style={{ maxHeight: '30px' }}
              alt='company-logo'
            />
          )}

          <img
            src={logo}
            alt={'logo'}
            style={{ maxHeight: '20px' }}
            className='ml-10'
          />
        </div>
        {!isEnvironmentsPage && (
          <>
            <div>/</div>
            <SelectEnv />
            <div>/</div>
            <SelectProjectView />
          </>
        )}
      </>
    );
  };

  const centerItems = () => {
    if (isEnvironmentsPage) return;

    return (
      <>
        {hasFeature('DOCUMENTATION') &&
          !isNilOrEmpty(currentEnvConfig?.documentationLink) && (
            <TopLevelTabButton
              type={'text'}
              onClick={() => {
                history.push(paths.documentation);
              }}
              $active={location.pathname === paths.documentation}
            >
              {isNilOrEmpty(currentEnvConfig?.documentationName)
                ? 'Documentation'
                : currentEnvConfig.documentationName}
            </TopLevelTabButton>
          )}
        {hasFeature('DASHBOARD') &&
          (!isNilOrEmpty(currentEnvConfig?.dashboardLink) ||
            !isNilOrEmpty(currentEnvConfig?.embedLink)) && (
            <TopLevelTabButton
              type={'text'}
              onClick={() => {
                history.push(paths.dashboard);
              }}
              $active={location.pathname === paths.dashboard}
            >
              {isNilOrEmpty(currentEnvConfig?.dashboardName)
                ? 'Dashboard'
                : currentEnvConfig.dashboardName}
            </TopLevelTabButton>
          )}
        <TopLevelTabButton
          type={'text'}
          onClick={() => {
            history.push(paths.visualization);
          }}
          $active={location.pathname === paths.visualization}
        >
          {isNilOrEmpty(currentEnvConfig?.visualizationName)
            ? 'Visualization'
            : currentEnvConfig.visualizationName}
        </TopLevelTabButton>
        {hasFeature('FILES') && !isNilOrEmpty(currentEnvConfig?.filesLink) && (
          <TopLevelTabButton
            type={'text'}
            onClick={() => {
              history.push(paths.files);
            }}
            $active={location.pathname === paths.files}
          >
            {isNilOrEmpty(currentEnvConfig?.filesName)
              ? 'Files'
              : currentEnvConfig.filesName}
          </TopLevelTabButton>
        )}
      </>
    );
  };

  const rightItems = () => {
    return (
      <>
        {!isEnvironmentsPage &&
          (hasPermission('di:MyProject:update') ||
            hasPermission('di:TeamProject:update')) && <SaveView />}
        {hasPermission('di:FileUpload:create') && (
          <div className='ml-12'>
            <Upload />
          </div>
        )}
        <div className='nav-bar__actions ml-12'>
          <AuthenticationButton />
        </div>
      </>
    );
  };

  return (
    <Navbar>
      <NavItemsOuter>
        <NavItemsInnerLeft>{leftItems()}</NavItemsInnerLeft>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavContainer>{centerItems()}</NavContainer>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavItemsInnerRight>{rightItems()}</NavItemsInnerRight>
      </NavItemsOuter>
    </Navbar>
  );
};

export default Header;
