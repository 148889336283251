import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { pathOr } from 'ramda';

import Header from '../../components/Header/Header';
import Text from '../../components/Text';
import ProjectCard from './ProjectCard';
import Resources from './Resources';
import GlobalLoading from '../../components/GlobalLoading';
import useEnvs from '../../hooks/calls/useEnvs';
import { setCurrentEnv } from '../../store/appSlice';
import { views } from '../../constants/env';
import store from '../../store';
import { setProjectView, reset as resetApp } from '../../store/appSlice';
import { addCartoToStore, reset as resetCarto } from '../../store/Carto';
import { redirectIfAnyCartoProject } from '../../utils/redirect';

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const YourEnvironments = () => {
  const { data, isLoading: isEnvListLoading } = useEnvs();
  const [envReset, setEnvReset] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const envList = pathOr([], ['envs'], data);
  const pageConfig = pathOr({}, ['org', 'page_config', 'environments'], data);

  useEffect(() => {
    if (envReset) return;

    dispatch(resetApp());
    dispatch(resetCarto());
    setEnvReset(true);
  }, [envReset]);

  useEffect(() => {
    if (envList.length < 1) return;
    redirectIfAnyCartoProject(envList);
  }, [envList]);

  const setEnvironment = (envId) => {
    dispatch(setCurrentEnv(envId));
    dispatch(setProjectView(views.MY_VIEW));
  };

  if (isEnvListLoading) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  const evalTemplate = (template, user) => {
    const handler = new Function(
      'user',
      'const tagged = (user) => `' + template + '`; return tagged(user)'
    );
    return handler(user);
  }

  const pageGreetingTemplate = pathOr(
    'Hello, ${user.name}',
    ['greeting'],
    pageConfig
  );
  const pageTitleTemplate = pathOr(
    'Your Environments',
    ['title'],
    pageConfig
  );
  const pageSubtitleTemplate = pathOr(
    'See all of your team projects below',
    ['subtitle'],
    pageConfig
  );

  return (
    <div>
      <Header page='environments' />
      <div className='p-48'>
        {pageGreetingTemplate !== false &&
          <Text className='text19 mb-24'>
            {evalTemplate(pageGreetingTemplate, user)}
          </Text>}
        {pageTitleTemplate !== false &&
          <Text className='text15 mb-4'>
            {evalTemplate(pageTitleTemplate, user)}
          </Text>}
        {pageSubtitleTemplate !== false &&
          <Text className='text16 mb-16'>
            {evalTemplate(pageSubtitleTemplate, user)}
          </Text>}
        <CardWrapper className='project-list-container'>
          {envList.map((env) => {
            return (
              <ProjectCard
                key={env.id}
                env={env}
                setEnvironment={setEnvironment}
              />
            );
          })}
        </CardWrapper>
      </div>
      <Resources />
    </div>
  );
};

export default YourEnvironments;
