import { useEffect, useState } from 'react';
import {
  CategoryWidget,
  HistogramWidget,
  RangeWidget,
  FormulaWidget,
} from '@carto/react-widgets';

import { replaceFormatters } from '../../../utils/formatters';

export default function WidgetWrapper(props) {
  const { cfg, hideWidget } = props;
  const [userAction, setUserAction] = useState(false);

  cfg.key = cfg.id;
  if (cfg.wrapperProps === undefined) cfg.wrapperProps = {};
  replaceFormatters(cfg, [
    'formatter',
    'xAxisFormatter',
    'yAxisFormatter',
    'tooltipFormatter',
  ]);

  if (hideWidget === true) {
    if (userAction === true) {
      cfg.wrapperProps.expanded = true;
    } else {
      cfg.wrapperProps.expanded = false;
      cfg.wrapperProps.onExpandedChange = () => {
        setUserAction(true);
      };
    }
  }

  useEffect(() => {
    if (hideWidget === false && userAction === true) setUserAction(false);
  }, [hideWidget]);

  let widget;
  switch (cfg.type) {
    case 'category':
      widget = createCategoryWidget(cfg);
      break;
    case 'range':
      widget = createRangeWidget(cfg);
      break;
    case 'histogram':
      widget = createHistogramWidget(cfg);
      break;
    case 'formula':
      widget = createFormulaWidget(cfg);
      break;
    default:
      console.log(`Unsupported Widget: ${cfg.type}`);
      widget = <></>;
  }

  return widget;
}

const createCategoryWidget = (cfg) => {
  return <CategoryWidget {...cfg} />;
};

const createRangeWidget = (cfg) => {
  return <RangeWidget {...cfg} />;
};

const createHistogramWidget = (cfg) => {
  if (cfg.buckets !== undefined && cfg.bins === undefined) {
    cfg.bins = cfg.buckets;
  }
  return <HistogramWidget {...cfg} />;
};

const createFormulaWidget = (cfg) => {
  return <FormulaWidget {...cfg} />;
};
