import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9897258978b304dddd2916a35cdd0ee4@o4507880048230400.ingest.us.sentry.io/4508049639014400",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
});
